import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/sanjay.jpg';

const AdvocateSanjayDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. Sanjay did his LLB from Osmania University, Hyderabad. He
                enrolled as an Advocate in the year 2014. He is specialised in
                the areas of Civil and Criminal laws. He is currently practising
                at Hon’ble High Court of Telangana, Civil Courts and Criminal
                Courts at Hyderabad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Mr. Sanjay Kamle, B.A., LLB.,
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateSanjayDetails;
